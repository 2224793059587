import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import LoginPage from './login-page/LoginPage';
import { AuthProvider } from './context/AuthContext';
import './App.css';

function App() {
  return (
    <Router>
      <AuthProvider>
        <LoginPage />
      </AuthProvider>
    </Router>
  );
}

export default App;
