import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const LoginBackground = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    // Scene setup
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0x000000); // Black background
    
    // Camera setup
    const camera = new THREE.PerspectiveCamera(
      75, 
      window.innerWidth / window.innerHeight, 
      0.1, 
      1000
    );
    camera.position.z = 5;
    
    // Renderer setup
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    
    // Add renderer to DOM
    mountRef.current.appendChild(renderer.domElement);
    
    // Create particles for the mist effect
    const particleCount = 1000;
    const particles = new THREE.BufferGeometry();
    const positions = new Float32Array(particleCount * 3);
    const colors = new Float32Array(particleCount * 3);
    
    // Create particles with random positions and red color variations
    for (let i = 0; i < particleCount * 3; i += 3) {
      // Position
      positions[i] = (Math.random() - 0.5) * 20;
      positions[i + 1] = (Math.random() - 0.5) * 20;
      positions[i + 2] = (Math.random() - 0.5) * 20;
      
      // Color - variations of red
      colors[i] = 0.7 + Math.random() * 0.3; // Red (0.7-1.0)
      colors[i + 1] = 0.0 + Math.random() * 0.2; // Green (0.0-0.2)
      colors[i + 2] = 0.0 + Math.random() * 0.2; // Blue (0.0-0.2)
    }
    
    particles.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    particles.setAttribute('color', new THREE.BufferAttribute(colors, 3));
    
    // Material for particles
    const particleMaterial = new THREE.PointsMaterial({
      size: 0.1,
      vertexColors: true,
      transparent: true,
      opacity: 0.8,
      blending: THREE.AdditiveBlending
    });
    
    // Create the particle system
    const particleSystem = new THREE.Points(particles, particleMaterial);
    scene.add(particleSystem);
    
    // Add a subtle red ambient light
    const ambientLight = new THREE.AmbientLight(0x330000);
    scene.add(ambientLight);
    
    // Add a red point light for dramatic effect
    const pointLight = new THREE.PointLight(0xff0000, 1, 100);
    pointLight.position.set(0, 0, 5);
    scene.add(pointLight);
    
    // Animation variables
    let frame = 0;
    
    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      
      frame += 0.005;
      
      // Rotate the particle system
      particleSystem.rotation.y = frame * 0.1;
      particleSystem.rotation.x = frame * 0.05;
      
      // Move particles to create flowing mist effect
      const positions = particles.attributes.position.array;
      
      for (let i = 0; i < particleCount * 3; i += 3) {
        // Apply sine wave movement to create flowing effect
        positions[i + 1] += Math.sin(frame + positions[i] * 0.1) * 0.01;
        positions[i] += Math.cos(frame + positions[i + 1] * 0.1) * 0.01;
        
        // Reset particles that move too far
        if (Math.abs(positions[i]) > 10) positions[i] = (Math.random() - 0.5) * 20;
        if (Math.abs(positions[i + 1]) > 10) positions[i + 1] = (Math.random() - 0.5) * 20;
      }
      
      particles.attributes.position.needsUpdate = true;
      
      // Pulse the point light
      pointLight.intensity = 1 + Math.sin(frame) * 0.5;
      
      renderer.render(scene, camera);
    };
    
    // Handle window resize
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };
    
    window.addEventListener('resize', handleResize);
    
    // Start animation
    animate();
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      mountRef.current?.removeChild(renderer.domElement);
      renderer.dispose();
    };
  }, []);
  
  return (
    <div 
      ref={mountRef} 
      style={{ 
        position: 'absolute', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%',
        zIndex: 0
      }}
    />
  );
};

export default LoginBackground; 